import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import ImpactCarousel from '../components/impactCarousel'

import {
  content,  
  cover,
  container,
  green,
  image,
  elleft,
  elright,
} from '../components/layout.module.css'

const Impact = () => {
  return (
    <Layout pageTitle="Impact">
        <div className={cover}>
          <StaticImage src="../images/impact/banner.jpg" alt="" style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            filter: "brightness(0.75)",
          }}
          loading="eager"
          layout="fullWidth"></StaticImage>
        <div className={container +' '+ content}>
          <h1>The Impact</h1>
        </div>
      </div>

      <h2>Stories from our Social Service Organizations (SSO<span>s</span>)</h2>

      <ImpactCarousel></ImpactCarousel>

      <div className={green} style={{position: "relative"}}>
        <StaticImage src="../images/impact/quote.jpg" alt="" className={elleft+' '+image}></StaticImage>
        <div className={container}>
          <blockquote className={elright}>
            <p>To stay connected with these families and know that our work has a tremendous effect on the family is rewarding.<br/><strong>– Millie Colon, Housing Services Manager, Way Finders</strong></p>
          </blockquote>
        </div>
      </div>

    </Layout>
  )
}

export default Impact