import React, { Component } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import Slider from "react-slick";
import ReactAudioPlayer from 'react-audio-player';
import NiviaHannah from "../static/audio/Nivia-and-Hannah-edited.mp3"
import Ashley2 from "../static/audio/Ashley-DeJesus-edited3.mp3"
import Erin from "../static/audio/Erin-DiBacco-edited.mp3"
import Kathrine from "../static/audio/Kathrine-Dove-edited.mp3"
import Shelia from "../static/audio/Shelia-Rhea-Nobles-edited.mp3"

import {
  gallery,
  container,
  flex,
  grey,
  white,
  border,
  larger,
} from '../components/layout.module.css'

export default class ImpactCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <>
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={5}
          focusOnSelect={true}
          className='navslide'
          infinite={false}
          responsive= {[
            {
              breakpoint: 580,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 2,
              }
            }
            ]}
        >
          <div className={gallery}>
            <button>
              <StaticImage src="../images/impact/projectbread.jpg" alt=""></StaticImage>
              <p>Project Bread</p>
            </button>
          </div>
          <div className={gallery}>
            <button><StaticImage src="../images/impact/communityservings.jpg" alt=""></StaticImage>
            <p>Community Servings</p>
            </button>
          </div>
          <div className={gallery}>
            <button><StaticImage src="../images/impact/bhn.jpg" alt=""></StaticImage>
            <p>BHN & Way Finders</p>
            </button>
          </div>
          <div className={gallery}>
            <button><StaticImage src="../images/impact/metrohousingboston.jpg" alt=""></StaticImage>
            <p>Metro Housing|Boston</p>
            </button>
          </div>
          <div className={gallery}>
            <button><StaticImage src="../images/impact/eliot.jpg" alt=""></StaticImage>
            <p>Eliot</p>
            </button>
          </div>
        </Slider>
        
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          adaptiveHeight= {true}
          className='impactslider'
        >
          <div>
            <div className={container}>
              <h3>Project Bread</h3>
              <div className={flex}>
                <div>
                  <p>Project Bread has worked in partnership with community health centers around the state for more than a decade to address food insecurity for individuals seeking care. As people move through a partner health center, Project Bread steps in to connect people to their FoodSource Hotline and other neighborhood resources and assist people in their applications for the Supplemental Nutrition Assistance Program (SNAP).</p>
                  <p>Project Bread saw great potential in enhancing their partnerships with healthcare through the Flexible Services Program (FSP):</p>
                  <blockquote className={white+' '+border}><p>We wanted to enhance the relationship between food security and health, building stronger bridges with healthcare as advocates around food security and nutrition.<br/><strong>– Jennifer Obadia, Senior Director Health Care Partnerships, Project Bread</strong></p></blockquote>
                  <p>Through FSP, Project Bread worked with C3 to develop a program that addresses the variety of reasons an individual or household might be experiencing food insecurity.  For some, the primary issue is not having enough income to purchase healthy food.  For others, they also need support with nutrition education and cooking supplies to be able to know how to prepare meals that support their health.</p>
                  <blockquote className={white+' '+border}><p>We’re not offering one thing; you don’t just get a gift card or a bag of food. C3 really encouraged us, and we encouraged them, to think of the full suite of services someone would need to reduce or eliminate the barriers.<br/><strong>– Jennifer Obadia, Senior Director Health Care Partnerships, Project Bread</strong></p></blockquote>
                </div>
                <div>
                  <p>Project Bread has a robust feedback loop in place, reaching out to members to determine if their needs were met and to learn about their overall experience with Project Bread’s coordinators. Feedback has been positive:</p>
                  <blockquote className={white+' '+border}><p>I ask them if they were treated with respect, and the answer is usually “yes, and…” They have many things to add because there is a built-in closeness to the coordinators that they develop.<br/><strong>– Hannah Benson, Program Assistant, Project Bread</strong></p></blockquote>
                  <p>Once a member is referred to Project Bread, a coordinator reaches out to determine the member’s needs and set a service plan. Member needs might include grocery store gift cards to augment their SNAP benefits or a referral for home-delivered meals if they are recovering from a health condition that makes cooking a challenge.  Other members might benefit from kitchen supplies and appliances, cooking classes, one-on-one nutrition counseling, and transportation assistance to the grocery store. Project Bread is also able to refer internally to the FoodSource Hotline to be sure members are maximizing their SNAP benefits.</p>
                  <StaticImage src="../images/impact/projectbread.jpg" alt=""></StaticImage>
                  <div className={grey+' '+larger}>
                    <p>Hear from Hannah Benson, Program Assistant and Nivia Lisboa, Nutrition Services Coordinator about the growth and success of Project Bread’s cooking classes:</p><ReactAudioPlayer src={NiviaHannah} controls/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={container}>
              <h3>Community Servings</h3>
              <div className={flex}>
                <div>
                  <p>Among other key programs, Community Servings delivers meals to individuals across Massachusetts that are tailored to their specific medical needs, such as diabetes, kidney disease, or cancer.</p>
                  <p>The Flexible Services Program has a clear impact on Community Servings’ ability to serve a greater number of people. Their existing program had limited spots and a waitlist, which meant those individuals who were the sickest received meals first. With FSP, increased funding allowed more people to receive meals, like those members who need access to healthy, prepared meals to stay out of the hospital.</p>
                  <p>Community Servings’ partnership with C3 has led to a unique program design, through which there is a collaboration between Project Bread for referrals.</p>
                  <blockquote className={white+' '+border}><p>C3 helped connect the dots between us and Project Bread, and now we actually get some of our referrals directly from Project Bread.<br/><strong>– Erin DiBacco, Director of Strategic Growth and Business Development, Community Servings</strong></p></blockquote>
                  <p>Once a member is referred to Community Servings, a Registered Dietitian Nutritionist conducts a nutrition assessment to set up the member’s meal plan. Members receive weekly meal deliveries of 10 meals for three months plus up to three nutrition counseling sessions. Meal deliveries can be extended for an additional three months if the member remains eligible and appropriate for the service.</p>
                </div>
                <div>
                  <StaticImage src="../images/impact/communityservings.jpg" alt=""></StaticImage>
                  <div className={grey+' '+larger}><p>Community Servings has also found ways for the data they collect to directly impact the structure of their FSP and ensure they are serving members better. Hear more from Erin DiBacco, Director of Strategic Growth and Business Development, about adapting their approach to address feedback from members:</p><ReactAudioPlayer src={Erin} controls/></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={container}>
              <h3>Behavioral Health Network (BHN) and Way Finders</h3>
              <div className={flex}>
                <div>
                  <p>Behavioral Health Network (BHN) and Way Finders are a unique partnership within C3’s Flexible Services Program, as these are two independent organizations working together to provide a full spectrum of services to address the housing stability needs of members in the Western Region of Massachusetts.</p>
                  <p>Housing instability can result from a variety of challenges, including limited income and inadequate housing conditions. These situations are often made more complex when members are living with behavioral health issues.  BHN and Way Finders work together to focus on their members by identifying all those challenges impacting housing and then setting and addressing as many goals as they can with the member. Those needs often extend beyond a simple housing search, to applying for additional benefits, getting new documents to prove identification, creating a budget, connecting with job training programs, and much more.</p>
                  <blockquote className={white+' '+border}><p>Our work is about empowering and connecting these people to the right resources. People have different situations, different levels of education, maybe they’re in recovery, and we can provide different programs so that they have the tools to overcome their own struggles. It’s about meeting them where they are and holding their hand through the process.<strong>– Maria Chambers, Senior Program Manager, Behavioral Health Network<br/></strong></p></blockquote>
                  <p>The partnership between BHN and Way Finders, supported by C3’s FSP, means they have additional resources to help them navigate and connect members to the right service.</p>
                </div>
                <div>
                  <blockquote className={white+' '+border}><p>It’s all about which connections which agency has…I also try to keep track of everything going on in the community because if I can’t help, there are probably other areas we can refer the client for help.<br/><strong> – Esmerelda Vazquez, Housing Specialist, Behavioral Health Network</strong></p></blockquote>
                  <p>A Housing Specialist at BHN contacts the member once they are referred by the health center and conducts a housing assessment. Based on the member’s needs, the BHN case manager may provide support to help the member avoid eviction, provide household goods to make their current living conditions better, or support their housing search. BHN may also connect the member with a Housing Specialist at Way Finders to navigate applications to state and federal housing programs to avoid eviction and to assist with housing search.</p>
                  <StaticImage src="../images/impact/bhn.jpg" alt=""></StaticImage>
                  <div className={grey+' '+larger}><p>Hear from Ashley DeJesus, Housing Navigator at Way Finders, about what she considers to be the greatest success of the Flexible Services Program:</p><ReactAudioPlayer src={Ashley2} controls/></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={container}>
              <h3>Metro Housing|Boston</h3>
              <div className={flex}>
                <div>
                  <p>Prior to the Flexible Services Program, Metro Housing|Boston had several years of experience working with healthcare partners, and that experience helped them to successfully navigate this new partnership with C3. Metro Housing|Boston has a deep understanding of the affordable housing system and housing-related issues. They work with individuals and families who are homeless or at risk of losing their homes and see the strong connection between housing and health:</p>
                  <blockquote className={white+' '+border}><p>Housing is the foundation for everything else. If someone is not housed, their health is not going to be well, either physical or mental. If they have children, then schooling and education will suffer, issues with nutrition, etc. We see it as a foundation of everything else. If someone is not stably housed, we know their health will suffer.<br/><strong>– Carla Beaudoin, Chief Strategic Partnership Officer, Metro Housing|Boston</strong></p></blockquote>
                  <p>The Flexible Services Program has allowed Metro Housing|Boston to tailor their approach with individual members, go at members’ pace, and serve and engage the population on a different level:</p>
                  <blockquote className={white+' '+border}><p>With Flex, we have that opportunity to sit with a member, to meet with them, and to go at their own pace. We move forward based on their capacity, based on how their feeling.<br/><strong>– Shelia Rhea Nobles, ACO Program Coordinator, Metro Housing|Boston</strong></p></blockquote>
                  <p>The additional staff resources and funds gave them an opportunity to take their time with members in helping to stabilize their situation and make an impact.</p>
                </div>
                <div>
                  <p>A case manager starts off by meeting with the member to complete a full intake process, working with them to identify all the barriers that may be preventing the member from obtaining or maintaining housing. The member and case manager talk about goals: What would you like to achieve? From that conversation, a step-by-step plan is created, and a team is built around the member. The case manager meets with the member on a regular basis to help the member meet the goals of the plan.</p>
                  <StaticImage src="../images/impact/metrohousingboston.jpg" alt=""></StaticImage>
                  <div className={grey+' '+larger}><p>Hear more from Shelia Rhea Nobles, Program Coordinator, about a particular member whose story and experience with Flexible Services stood out to her:</p><ReactAudioPlayer src={Shelia} controls/></div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={container}>
              <h3>Eliot Community Human Services</h3>
              <div className={flex}>
                <div>
                  <p>As a multi-service agency, Eliot Community Human Services (Eliot) has a long history working with healthcare partners and community health centers. They provide a wide variety of programming aimed at working with individuals to address behavioral health needs, care coordination, and housing services, so they came into the Flexible Services Program with a deep understanding of this vulnerable population and ways to coordinate services to support housing stability. Eliot’s partnership with C3 is unique from their other ACO partnerships in that C3 primarily refers members who are currently homeless.</p>
                  <blockquote className={white+' '+border}><p>This is a population that needs a program that can really evaluate the many needs and barriers that these folks face every day. When you can effectively address so many different barriers, the outcomes of that person’s situation are so incredibly rewarding.<br/><strong>– Delilah Kiely, Flexible Services Program Director, Eliot Community Human Services</strong></p></blockquote>
                  <p>Eliot’s experienced housing coordinators allow them to really tailor the member’s experience based on their needs and to build trusted relationships between coordinators and members:</p>
                  <blockquote className={white+' '+border}><p>The positive impact is not only in our outcomes, but also building those relationships and giving them someone who is just there supporting them in a time that is probably the hardest in their lives.<br/><strong>– Kathrine Dove, Clinical Coordinator, Elliot Community Human Services</strong></p></blockquote>
                </div>
                <div>
                  <p>Once Eliot receives a referral, a Housing Coordinator works with a member to identify their housing needs and overall goal for their participation in FSP. The coordinator then works closely with the member to access all available resources to help the member reach their goal. Goals could include helping a member improve their financial stability or identifying community resources to attain housing.</p>
                  <StaticImage src="../images/impact/eliot.jpg" alt=""></StaticImage>
                  <div className={grey+' '+larger}><p>Hear from Kathrine Dove, Clinical Coordinator, about how she personalizes a member’s experience to celebrate reaching their goals:</p><ReactAudioPlayer src={Kathrine} controls/></div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}